import { useSplitsLazy, useSplitData } from '@mentimeter/splitio';

export function useMonthlySplit() {
  const { country } = useSplitData();
  const lazyTreatment = useSplitsLazy();
  const isInMonthlyTreatment =
    !!country &&
    lazyTreatment.isReady &&
    lazyTreatment.getSplits(['Monthly_Billing'], {
      splitAttributes: { country },
    }).Monthly_Billing === 'on';

  return {
    isInMonthlyTreatment,
    experimentIsReady: lazyTreatment.isReady,
  };
}
