import { cva } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva(['border-[1px]', 'border-solid'], {
  variants: {
    disabled: {
      true: ['border-disabled'],
      false: ['border-weak'],
    },
    allowAnimation: {
      true: ['transition duration-150 ease-in-out'],
      false: [''],
    },
  },
});
