import React from 'react';
import type { PlanT, MetaT } from '@mentimeter/plans-content';
import { AnimatedTextSkeleton } from '@mentimeter/plans-content';
import { useMatch } from '@mentimeter/ragnar-device';
import { getFormattedPrice } from '@mentimeter/billing';
import { Badge } from '@mentimeter/ragnar-ui/badge';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Usps } from './Usps';
import { BuyPlanButton } from './BuyPlanButton';

const PLAN_SECTIONS_COUNT = 5;
const PLAN_WIDTH = 320;
const GRID_GAP = 16;

export const PlansPreviewGrid = ({ plans }: { plans: PlanT[] }) => {
  const isCompactMode = useMatch({ lessThan: 4 });
  const numberOfPlans = plans.length;
  const totalGridGap = (numberOfPlans - 1) * GRID_GAP;
  const totalMaxWidth = PLAN_WIDTH * numberOfPlans + totalGridGap;
  const plansItemWidth = isCompactMode ? PLAN_WIDTH : totalMaxWidth;

  return (
    <Box
      maxWidth={['100%', plansItemWidth, plansItemWidth, plansItemWidth]}
      height="100%"
      display="grid"
      gridAutoFlow={isCompactMode ? 'row' : 'column'}
      gridTemplateColumns={
        isCompactMode ? undefined : `repeat(${numberOfPlans}, 1fr)`
      }
      gridTemplateRows={`repeat(${PLAN_SECTIONS_COUNT - 1}, max-content) 1fr`}
      extend={({ theme }) => ({
        columnGap: `${theme.space[3]}px`,
      })}
    >
      {plans.map((plan) => {
        const sections = getPlanSections(plan, isCompactMode);

        return sections.map((section, index) => {
          const isFirstSection = index === 0;
          const isLastSection = index === sections.length - 1;

          return (
            <PlanCell
              key={'section-' + section.key}
              showTopBorder={isFirstSection}
              showBottomBorder={isLastSection}
              highlight={plan.highlight}
            >
              {section}
            </PlanCell>
          );
        });
      })}
    </Box>
  );
};

export const getPlanSections = (plan: PlanT, isCompactMode: boolean) => {
  const PlanIcon = plan.icon;

  const showEducationalDiscount = plan.discount && !plan.isPriceLoading;

  const showYearlyDiscount = (item: MetaT) => {
    return (
      !plan.discount &&
      item.yearlyDiscount &&
      item.yearlyDiscountText &&
      !plan.isPriceLoading
    );
  };

  return [
    <React.Fragment key={plan.id + '-heading'}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Text
          as="h2"
          color="text"
          fontSize={isCompactMode ? 6 : 4}
          fontWeight="semiBold"
        >
          {plan.heading}
        </Text>
        <Box ml={1} overflow="visible">
          <PlanIcon />
        </Box>
      </Box>
    </React.Fragment>,

    <React.Fragment key={plan.id + '-price'}>
      <Box width="100%" flexDirection="column" flex="1 1 auto">
        {showEducationalDiscount && plan.discount && (
          <Box flexDirection="row" alignItems="center">
            <Text
              color="textWeaker"
              extend={() => ({ textDecoration: 'line-through' })}
            >
              {plan?.currencySymbol &&
                plan?.priceFormat &&
                getFormattedPrice(
                  plan.discount.baseAmount,
                  plan.currencySymbol,
                  plan.priceFormat,
                )}
            </Text>
            <Badge
              px={2}
              ml={2}
              type="text"
              bg="positiveWeak"
              color="onPositiveWeak"
              text={plan.discount.save}
              compact
              data-testid={`${plan.id}-plan-discount-badge`}
            />
          </Box>
        )}

        <Box flexDirection="row" width="100%">
          {plan.isPriceLoading ? (
            <AnimatedTextSkeleton />
          ) : (
            <Text
              color="text"
              fontWeight="bold"
              fontSize={6}
              lineHeight="none"
              mt={1}
              data-testid={`${plan.id}-plan-price`}
            >
              {plan.price}
              {Boolean(plan.pricePostfix) && (
                <Box
                  display="inline-block"
                  extend={() => ({
                    verticalAlign: 'top',
                  })}
                >
                  <Text
                    fontWeight="bold"
                    color="text"
                    extend={() => ({
                      display: 'block',
                    })}
                  >
                    {plan.pricePostfix}
                  </Text>
                </Box>
              )}
            </Text>
          )}
        </Box>
      </Box>
    </React.Fragment>,
    <React.Fragment key={plan.id + '-plan_info'}>
      {plan.newPriceMeta?.map((item) => (
        <Box key={item.text} flexDirection="row" alignItems="center">
          <Text key={item.text} color="textWeaker" fontSize={1}>
            {item.text}
            {showYearlyDiscount(item) && (
              <Box display="inline-block">
                <Badge
                  px={2}
                  ml={2}
                  type="text"
                  bg="positiveWeak"
                  color="onPositiveWeak"
                  text={item.yearlyDiscountText}
                  compact
                />
              </Box>
            )}
          </Text>
        </Box>
      ))}
    </React.Fragment>,
    <React.Fragment key={plan.id + '-button'}>
      <BuyPlanButton
        highlight={plan.highlight}
        {...plan.action}
        data-testid={`${plan.id}-plan-link`}
        onClick={onClickHandler}
      />
    </React.Fragment>,
    <React.Fragment key={plan.id + '-usps'}>
      <Usps heading={plan.uspHeading} usps={plan.usp} />
    </React.Fragment>,
  ];

  function onClickHandler(e: React.MouseEvent) {
    plan.action.onClick?.(e);
    plan.track?.();
  }
};

interface PlanCellProps {
  children: React.ReactNode;
  highlight?: boolean | undefined;
  showTopBorder?: boolean;
  showBottomBorder?: boolean;
  extraBottomPadding?: boolean;
}

export const PlanCell = ({
  children,
  highlight = false,
  showTopBorder = false,
  showBottomBorder = false,
  extraBottomPadding = false,
}: PlanCellProps) => {
  const isCompactMode = useMatch({ lessThan: 4 });
  const maxTopPadding = showTopBorder ? 4 : 2;
  const maxBottomPadding = showBottomBorder ? (extraBottomPadding ? 6 : 4) : 2;

  return (
    <Box
      px={[4, 4, 3, 4]}
      pt={maxTopPadding}
      pb={maxBottomPadding}
      mb={showBottomBorder && isCompactMode ? 4 : 0}
      bg={highlight ? 'secondaryWeak' : 'surfaceSunken'}
      extend={({ theme }) => ({
        alignSelf: 'stretch',
        borderTopLeftRadius: showTopBorder ? `${theme.radii[1]}px` : 0,
        borderTopRightRadius: showTopBorder ? `${theme.radii[1]}px` : 0,
        borderBottomLeftRadius: showBottomBorder ? `${theme.radii[1]}px` : 0,
        borderBottomRightRadius: showBottomBorder ? `${theme.radii[1]}px` : 0,
      })}
    >
      {children}
    </Box>
  );
};
