import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../box';
import { variants } from './PillSelectHighlight.variants';

interface PillSelectHighlightProps {
  disabled?: boolean | undefined;
  allowAnimation?: boolean | undefined;
  highlightWidth: number;
  highlightOffset: number;
}

export function PillSelectHighlight({
  disabled = false,
  allowAnimation = true,
  highlightWidth,
  highlightOffset,
}: PillSelectHighlightProps) {
  const variantClasses = variants({ disabled, allowAnimation });

  return (
    <Box
      role="presentation"
      className={clsx(
        variantClasses,
        'absolute',
        'bg-button-primary-reverse',
        'rounded-full',
        'h-full',
        'left-0',
        'w-[var(--pillselect-highlight-width)]',
        'translate-x-[var(--pillselect-highlight-offset)]',
      )}
      style={
        {
          '--pillselect-highlight-width': `${highlightWidth}px`,
          '--pillselect-highlight-offset': `${highlightOffset}px`,
        } as React.CSSProperties
      }
    />
  );
}
