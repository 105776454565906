import React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { variants } from './PillSelectItem.variants';

export interface PillItemT {
  'aria-label'?: string | undefined;
  disabled?: boolean | undefined;
  id?: string | undefined;
  value: string;
  label: string;
}

interface PillOption extends PillItemT {
  selected: boolean;
  onClick: () => void;
}

export const PillSelectItem = React.forwardRef<HTMLButtonElement, PillOption>(
  (
    { id, label, 'aria-label': ariaLabel, disabled, selected, onClick },
    ref,
  ) => {
    const defaultClasses = [
      'pt-3',
      'pb-1.5',
      'px-4',
      'z-[1]',
      'bg-transparent',
      'rounded-full',
      'text-87.5',
      'leading-100',
      'flex',
      'relative',
      'items-center',
      'flex-col',
      'border-[1]',
      'border-solid',
      'border-transparent',
      'font-body',
      'font-semibold',
      'cursor-pointer',
      'disabled:cursor-not-allowed',
      '[&:enabled]:focus-visible:outline',
      '[&:enabled]:focus-visible:outline-interactive-focused',
      '[&:enabled]:focus-visible:outline-offset-2',
      '[&:enabled]:focus-visible:outline-4',
      'transition-color',
      'transition-outline',
      'duration-150',
      'ease-in-out',
      'flex-1',
      'whitespace-nowrap',
    ];

    const variantClasses = variants({
      variant: selected ? 'selected' : 'default',
    });

    return (
      <button
        type="button"
        role="tab"
        id={id}
        ref={ref}
        disabled={disabled}
        aria-selected={selected}
        aria-label={ariaLabel}
        onClick={() => onClick()}
        className={cn(defaultClasses, variantClasses)}
      >
        {label}
      </button>
    );
  },
);
