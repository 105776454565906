import type { TFunction } from '@mentimeter/i18n';
import type { UserPoliciesT } from '@mentimeter/user';
import { PlanCategory } from '@mentimeter/http-clients';
import type {
  LocalPricingT,
  UpdateOptionsResponseT,
} from '@mentimeter/billing';
import { getFormattedPrice, CurrencyCode } from '@mentimeter/billing';
import type { PlanT } from './types';
import { BasicIcon, EnterpriseIcon, FreeIcon, ProIcon } from './icons';
import EngagementProgressIllustration from './illustrations/engagement_progress_illustration.png';

export interface GetPlansPreviewConfigT {
  isEducation: boolean;
  isFreeUser: boolean;
  userPolicies: Pick<
    UserPoliciesT,
    | 'isTrialUser'
    | 'isGenericProUser'
    | 'isGenericBasicYearlyUser'
    | 'isGenericBasicMonthlyUser'
    | 'isEnterpriseUser'
  >;
  prices: LocalPricingT;
  loggedIn: boolean;
  isPriceLoading: boolean;
  monthlyToggled: boolean;
  possibleUpdates: UpdateOptionsResponseT | undefined;
  freePlanAction: PlanT['action'];
  highlightedPlan: string;
  showRecommendedBadge: boolean;
  isInMonthlyTreatment: boolean;
  isOnboarding: boolean;
  isInStairCaseTreatment: boolean;
  host: string;
  Link: React.ComponentType<{ href?: string; children?: React.ReactNode }>;
  trackPlan: (arg0: string) => void;
  t: TFunction;
  country?: string | null;
  userEngagementLimitProgress?: number | undefined;
  isInPlanNameExperiment?: boolean;
}

interface PlansPreviewConfigT {
  plans: Array<PlanT>;
}

export function getPlansPreviewConfig({
  isEducation,
  isFreeUser,
  userPolicies,
  prices,
  loggedIn,
  isPriceLoading,
  monthlyToggled,
  possibleUpdates,
  freePlanAction,
  highlightedPlan,
  showRecommendedBadge,
  isInMonthlyTreatment,
  isOnboarding,
  isInStairCaseTreatment,
  host,
  trackPlan,
  t,
  country,
  userEngagementLimitProgress,
  isInPlanNameExperiment = false,
}: GetPlansPreviewConfigT): PlansPreviewConfigT {
  const {
    isGenericBasicMonthlyUser,
    isGenericBasicYearlyUser,
    isGenericProUser,
    isTrialUser,
    isEnterpriseUser,
  } = userPolicies;
  const countryIsNotUS = country && country !== 'US';
  const currencyIsUSD = prices?.currency?.code === CurrencyCode.USD;
  const pricePostfix = countryIsNotUS && currencyIsUSD ? CurrencyCode.USD : '';
  const userIsEngaged = (userEngagementLimitProgress ?? 0) > 0;

  function isPlanCategoryMatch(PLAN: string): boolean {
    // Show enabled buy buttons based on loggedin status and possible upgrades
    return (
      !loggedIn ||
      (possibleUpdates?.update_options?.some(
        (plan) => plan?.plan_category === PLAN,
      ) ??
        false)
    );
  }

  return {
    plans: [
      {
        id: 'free',
        category: PlanCategory.FREE,
        icon: FreeIcon,
        isCurrentSubscription: isFreeUser,
        heading: t('plans.free.heading'),
        subheading: [
          {
            text: freeSubheading(t),
          },
        ],
        currencySymbol: prices.currency.symbol,
        priceFormat: prices.format,
        recommended: showRecommendedBadge && highlightedPlan === 'free',
        highlight: highlightedPlan === 'free',
        decimalSeparator: prices?.format.decimalSeparator,
        price: prices ? getPlanPrice(prices) : '',
        amount: null,
        tiers: null,
        pricePostfix,
        isPriceLoading,
        priceMeta: [
          {
            text: isEducation
              ? t('plans.education.no_credit_card_needed')
              : t('plans.no_credit_card_needed'),
          },
        ],
        action: freePlanAction,
        usp: freeUsps(t, {
          userIsEngaged,
          isInStairCaseTreatment,
        }),
        uspHeading: t('plans.free.usp_heading'),
        hideOnMobileOrTablet: false,
        track: () => trackPlan(isEducation ? 'educational-free' : 'free'),
      },
      {
        id: 'basic',
        currencySymbol: prices.currency.symbol,
        priceFormat: prices.format,
        category: isEducation
          ? monthlyToggled
            ? PlanCategory.EDUCATIONAL_BASIC_MONTHLY
            : PlanCategory.EDUCATIONAL_BASIC
          : monthlyToggled
            ? PlanCategory.BASIC_MONTHLY
            : PlanCategory.BASIC,
        icon: BasicIcon,
        isCurrentSubscription: monthlyToggled
          ? isGenericBasicMonthlyUser
          : isGenericBasicYearlyUser,
        isChangingBillingCycle: isGenericBasicMonthlyUser && !monthlyToggled,
        heading: isInPlanNameExperiment
          ? t('plans.basic.plan_name_experiment_heading')
          : t('plans.basic.heading'),
        subheading: [
          {
            text: basicSubheading(t, isEducation),
          },
        ],
        recommended: showRecommendedBadge && highlightedPlan === 'basic',
        highlight: highlightedPlan === 'basic',
        decimalSeparator: prices?.format.decimalSeparator,
        price: prices
          ? getFormattedPricesForBasic({ isEducation, monthlyToggled, prices })
          : '',
        amount: getAmountsForBasic({ isEducation, monthlyToggled, prices }),
        tiers: prices?.plans[PlanCategory.BASIC].tiers,
        pricePostfix,
        isPriceLoading,
        priceMeta: [
          {
            text: monthlyToggled
              ? t('plans.billed_monthly')
              : t('plans.billed_annually'),
            yearlyDiscount: !monthlyToggled && isInMonthlyTreatment,
          },
          {
            text: t('plans.price_per_presenter'),
            tooltips: [
              {
                target: t('plans.price_per_presenter_tooltip_target'),
                text: t('plans.price_per_presenter_tooltip'),
              },
            ],
          },
        ],
        newPriceMeta: [
          { text: t('plans.price_per_presenter_per_month') },
          {
            text: monthlyToggled
              ? t('plans.billed_monthly_excl_tax')
              : t('plans.billed_annually_excl_tax'),
            yearlyDiscount: !monthlyToggled && isInMonthlyTreatment,
            yearlyDiscountText: t('plans.save_discount', {
              discount: '33%',
            }),
          },
        ],
        discount: isEducation
          ? {
              baseAmount: getAmountsForBasic({
                isEducation: false,
                monthlyToggled,
                prices,
              }),
              tiers: prices.plans[PlanCategory.BASIC].tiers,
              percent: t('plans.discount', {
                discount: '30%',
              }),
              save: t('plans.save_discount', {
                discount: '30%',
              }),
            }
          : undefined,
        action: {
          href: absoluteUrl(
            host,
            upgradeActionHref({ isEducation, monthlyToggled }),
          ),
          children: isGenericBasicYearlyUser
            ? t('plans.add_licenses')
            : isGenericBasicMonthlyUser && monthlyToggled
              ? t('plans.add_licenses')
              : isGenericBasicMonthlyUser && !monthlyToggled
                ? t('plans.switch_to_yearly')
                : isFreeUser
                  ? isInPlanNameExperiment
                    ? t('plans.basic.plan_name_experiment_upgrade_to_basic')
                    : t('plans.basic.upgrade_to_basic')
                  : isInPlanNameExperiment
                    ? t('plans.basic.plan_name_experiment_buy_basic')
                    : t('plans.basic.buy_basic'),
          disabled:
            isEnterpriseUser ||
            (!isTrialUser && isGenericProUser) ||
            !isPlanCategoryMatch(isEducation ? 'educational-basic' : 'basic'),
        },
        usp: basicUsps(t, { isInStairCaseTreatment }),
        uspHeading: t('plans.basic.usp_heading'),
        track: () => {
          if (isEducation) {
            return trackPlan(
              monthlyToggled && !isGenericBasicMonthlyUser
                ? 'educational-basic-monthly'
                : 'educational-basic',
            );
          } else {
            return trackPlan(
              monthlyToggled && !isGenericBasicMonthlyUser
                ? 'basic-monthly'
                : 'basic',
            );
          }
        },
      },
      {
        id: 'pro',
        amount:
          prices?.plans[
            isEducation ? PlanCategory.EDUCATIONAL_PRO : PlanCategory.PRO
          ].amount,
        category: isEducation ? PlanCategory.EDUCATIONAL_PRO : PlanCategory.PRO,
        icon: ProIcon,
        isCurrentSubscription: isGenericProUser,
        isDisabled: monthlyToggled,
        heading:
          isInPlanNameExperiment && !isEducation
            ? t('plans.pro.plan_name_experiment_heading')
            : t('plans.pro.heading'),
        subheading: [
          {
            text: proSubheading(t, {
              isEducation,
              isOnboarding,
            }),
          },
        ],
        recommended: showRecommendedBadge && highlightedPlan === 'pro',
        highlight: highlightedPlan === 'pro',
        decimalSeparator: prices?.format.decimalSeparator,
        price: prices
          ? getFormattedPrice(
              isEducation
                ? prices.plans[PlanCategory.EDUCATIONAL_PRO].amount
                : prices.plans[PlanCategory.PRO].amount,
              prices.currency.symbol,
              prices.format,
            )
          : '',
        tiers: isEducation
          ? prices.plans[PlanCategory.EDUCATIONAL_PRO].tiers
          : prices.plans[PlanCategory.PRO].tiers,
        currencySymbol: prices.currency.symbol,
        priceFormat: prices.format,
        pricePostfix,
        isPriceLoading,
        action: {
          href: absoluteUrl(
            host,
            isEducation ? '/app/checkout/educational-pro' : '/app/checkout/pro',
          ),
          children: monthlyToggled
            ? t('plans.only_yearly')
            : isGenericProUser && !isTrialUser
              ? t('plans.add_licenses')
              : isFreeUser || isGenericBasicYearlyUser
                ? isInPlanNameExperiment && !isEducation
                  ? t('plans.pro.plan_name_experiment_upgrade_to_pro')
                  : t('plans.pro.upgrade_to_pro')
                : isInPlanNameExperiment && !isEducation
                  ? t('plans.pro.plan_name_experiment_buy_pro')
                  : t('plans.pro.buy_pro'),
          disabled:
            isEnterpriseUser ||
            monthlyToggled ||
            !isPlanCategoryMatch(isEducation ? 'educational-pro' : 'pro'),
        },
        usp: proUsps(t, { isInStairCaseTreatment }),
        priceMeta: [
          { text: t('plans.billed_annually') },
          {
            text: t('plans.price_per_presenter'),
            tooltips: [
              {
                target: t('plans.price_per_presenter_tooltip_target'),
                text: t('plans.price_per_presenter_tooltip'),
              },
            ],
          },
        ],
        newPriceMeta: [
          { text: t('plans.price_per_presenter_per_month') },
          { text: t('plans.billed_annually_excl_tax') },
        ],
        discount: isEducation
          ? {
              baseAmount: prices ? prices.plans[PlanCategory.PRO].amount : null,
              tiers: prices.plans[PlanCategory.PRO].tiers,
              percent: t('plans.discount', {
                discount: '40%',
              }),
              save: t('plans.save_discount', {
                discount: '40%',
              }),
            }
          : undefined,
        uspHeading: isInPlanNameExperiment
          ? t('plans.pro.plan_name_experiment_usp_heading')
          : t('plans.pro.usp_heading'),
        track: () => trackPlan(isEducation ? 'educational-pro' : 'pro'),
      },
      {
        id: 'enterprise',
        amount: null,
        category: PlanCategory.ENTERPRISE,
        icon: EnterpriseIcon,
        isDisabled: monthlyToggled,
        currencySymbol: prices.currency.symbol,
        priceFormat: prices.format,
        heading: isEducation
          ? t('plans.campus.heading')
          : t('plans.enterprise.heading'),
        subheading: [
          {
            text: enterpriseSubheading(t, isEducation),
          },
        ],
        recommended: showRecommendedBadge && highlightedPlan === 'enterprise',
        highlight: highlightedPlan === 'enterprise',
        price: t('plans.custom'),
        tiers: null,
        isPriceLoading: false,
        smallerPrice: true,
        action: {
          href: isEducation
            ? absoluteUrl(host, '/education/enterprise')
            : absoluteUrl(host, '/enterprise'),
          children: monthlyToggled
            ? t('plans.only_yearly')
            : t('plans.learn_more'),
          disabled: monthlyToggled,
        },
        usp: enterpriseUsps(t, { isInStairCaseTreatment }),
        decimalSeparator: prices?.format.decimalSeparator,
        meta: '',
        newPriceMeta: [],
        uspHeading: isEducation
          ? t('plans.campus.usp_heading')
          : isInPlanNameExperiment
            ? t('plans.enterprise.plan_name_experiment_usp_heading')
            : t('plans.enterprise.usp_heading'),
        track: () =>
          trackPlan(isEducation ? 'educational-enterprise' : 'enterprise'),
      },
    ],
  };
}

function getPlanPrice(prices: LocalPricingT): string {
  const formattedPrice = getFormattedPrice(
    0,
    prices.currency.symbol,
    prices.format,
  );
  return formattedPrice;
}

function getAmountsForBasic({
  isEducation,
  monthlyToggled,
  prices,
}: {
  isEducation: boolean;
  monthlyToggled: boolean;
  prices: LocalPricingT;
}) {
  if (isEducation && monthlyToggled) {
    return prices.plans[PlanCategory.EDUCATIONAL_BASIC_MONTHLY].amount!;
  }

  if (isEducation && !monthlyToggled) {
    return prices.plans[PlanCategory.EDUCATIONAL_BASIC].amount!;
  }

  if (!isEducation && monthlyToggled) {
    return prices.plans[PlanCategory.BASIC_MONTHLY].amount!;
  }

  return prices.plans[PlanCategory.BASIC].amount!;
}

function getFormattedPricesForBasic({
  isEducation,
  monthlyToggled,
  prices,
}: {
  isEducation: boolean;
  monthlyToggled: boolean;
  prices: LocalPricingT;
}) {
  if (isEducation) {
    return getFormattedPrice(
      getAmountsForBasic({ isEducation, monthlyToggled, prices }),
      prices.currency.symbol,
      prices.format,
    );
  }

  return getFormattedPrice(
    getAmountsForBasic({ isEducation, monthlyToggled, prices }),
    prices.currency.symbol,
    prices.format,
  );
}

function upgradeActionHref({
  isEducation,
  monthlyToggled,
}: {
  isEducation: boolean;
  monthlyToggled: boolean;
}) {
  if (isEducation) {
    return monthlyToggled
      ? '/app/checkout/educational-basic-monthly'
      : '/app/checkout/educational-basic';
  }

  if (monthlyToggled) {
    return '/app/checkout/basic-monthly';
  }

  return '/app/checkout/basic';
}

function absoluteUrl(host: string, url: string): string {
  return host + url;
}

function freeSubheading(t: TFunction) {
  return t('plans.free.subheading_easiest_way_to_try');
}

function basicSubheading(t: TFunction, isEducation: boolean) {
  if (isEducation) {
    return t('plans.basic.subheading_advanced_for_educators');
  }

  return t('plans.basic.subheading_advanced_for_presenters');
}

function proSubheading(
  t: TFunction,
  {
    isEducation,
    isOnboarding,
  }: {
    isEducation: boolean;
    isOnboarding: boolean;
  },
) {
  if (isEducation) {
    return t('plans.pro.subheading_tools_for_customization');
  }

  if (isOnboarding) {
    return t('plans.pro.subheading_full_access_mentimeter');
  }

  return t('plans.pro.subheading_tools_for_teams');
}

function enterpriseSubheading(t: TFunction, isEducation: boolean) {
  if (isEducation) {
    return t('plans.enterprise.subheading_customized_for_educators');
  }

  return t('plans.enterprise.subheading_customized_for_organisations');
}

function freeUsps(
  t: TFunction,
  {
    userIsEngaged,
    isInStairCaseTreatment,
  }: {
    userIsEngaged: boolean;
    isInStairCaseTreatment?: boolean;
  },
) {
  if (isInStairCaseTreatment) {
    return [
      {
        text: t('plans.usps.participants_per_month', {
          participants: 50,
        }),
        subheading: {
          text: t('plans.usps.go_over_limit_once_monthly'),

          tooltips: [
            {
              target: t('plans.usps.go_over_limit_once_monthly'),
              text: userIsEngaged
                ? t('plans.usps.go_over_limit_once_monthly_description_engaged')
                : t(
                    'plans.usps.go_over_limit_once_monthly_description_unengaged',
                  ),
              illustrationUrl: EngagementProgressIllustration.src,
            },
          ],
          iconTooltip: {
            text: userIsEngaged
              ? t('plans.usps.go_over_limit_once_monthly_description_engaged')
              : t(
                  'plans.usps.go_over_limit_once_monthly_description_unengaged',
                ),
            illustrationUrl: EngagementProgressIllustration.src,
          },
        },
        tooltips: [
          {
            target: t('plans.usps.participants_per_month', {
              participants: 50,
            }),
            text: t('plans.participants_per_month_description_v2'),
          },
        ],
      },
      {
        text: t('plans.usps.limited_engagement_slides'),
      },
    ];
  }
  return [
    {
      text: t('plans.usps.participants_per_month', {
        participants: 50,
      }),
      subheading: {
        text: t('plans.usps.go_over_limit_once_monthly'),

        tooltips: [
          {
            target: t('plans.usps.go_over_limit_once_monthly'),
            text: userIsEngaged
              ? t('plans.usps.go_over_limit_once_monthly_description_engaged')
              : t(
                  'plans.usps.go_over_limit_once_monthly_description_unengaged',
                ),
            illustrationUrl: EngagementProgressIllustration.src,
          },
        ],
        iconTooltip: {
          text: userIsEngaged
            ? t('plans.usps.go_over_limit_once_monthly_description_engaged')
            : t('plans.usps.go_over_limit_once_monthly_description_unengaged'),
          illustrationUrl: EngagementProgressIllustration.src,
        },
      },
      tooltips: [
        {
          target: t('plans.usps.participants_per_month', {
            participants: 50,
          }),
          text: t('plans.participants_per_month_description_v2'),
        },
      ],
    },
    {
      text: t('plans.usps.limited_engagement_slides'),
    },
    {
      text: t('plans.usps.co_edit_presentations_v2'),
    },
  ];
}

function basicUsps(
  t: TFunction,
  { isInStairCaseTreatment }: { isInStairCaseTreatment?: boolean },
) {
  if (isInStairCaseTreatment) {
    return [
      {
        text: t('plans.usps.unlimited_participants'),
      },
      { text: t('plans.usps.import_slides') },
      { text: t('plans.usps.export_results_to_excel') },
      { text: t('plans.make_presentations_private') },
    ];
  }
  return [
    {
      text: t('plans.usps.unlimited_participants'),
    },
    { text: t('plans.usps.import_slides') },
    { text: t('plans.usps.export_results_to_excel') },
  ];
}

function proUsps(
  t: TFunction,
  { isInStairCaseTreatment }: { isInStairCaseTreatment?: boolean },
) {
  if (isInStairCaseTreatment) {
    return [
      {
        text: t('plans.usps.add_company_logo_and_branding'),
      },
      { text: t('plans.custom_colors') },
      { text: t('plans.custom_themes') },
      {
        text: t('plans.usps.create_team_templates'),
      },
      {
        text: t('plans.usps.co_edit_presentations_privately'),
        tooltips: [
          {
            target: t('plans.usps.co_edit_presentations_privately'),
            text: t('plans.usps.co_edit_presentations_privately_description'),
          },
        ],
      },
    ];
  }
  return [
    {
      text: t('plans.usps.add_company_logo_and_branding'),
    },
    {
      text: t('plans.usps.create_team_templates'),
    },
    {
      text: t('plans.usps.co_edit_presentations_privately'),
      tooltips: [
        {
          target: t('plans.usps.co_edit_presentations_privately'),
          text: t('plans.usps.co_edit_presentations_privately_description'),
        },
      ],
    },
  ];
}

function enterpriseUsps(
  t: TFunction,
  { isInStairCaseTreatment }: { isInStairCaseTreatment: boolean },
) {
  if (isInStairCaseTreatment) {
    return [
      { text: t('plans.usps.sso') },
      { text: t('plans.scim') },
      { text: t('plans.custom_data_retention') },
      { text: t('plans.priority_support') },
      { text: t('plans.usps.success_manager') },
      { text: t('plans.custom_onboarding') },
      { text: t('plans.usps.shared_templates') },
      { text: t('plans.usps.organize_team_into_groups') },
      { text: t('plans.teams_workspace_insights') },
    ];
  }
  return [
    { text: t('plans.usps.sso') },
    { text: t('plans.scim') },
    { text: t('plans.usps.success_manager') },
    { text: t('plans.usps.shared_templates') },
  ];
}
