import { cva } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva(
  [
    'after:content-zwsp',
    'after:mt-1',
    'after:h-[2px]',
    'after:w-0',
    'after:opacity-0',
    'after:transition-[width,opacity]',
    'after:ease-[cubic-bezier(0.76,-0.71,0.3,1.71)]',
    'after:duration-300',
    'after:delay-100',
    'after:bg-secondary',
    'after:rounded-full',
    'disabled:after:bg-on-disabled',
  ],
  {
    variants: {
      variant: {
        default: [
          'text-weaker',
          'disabled:text-disabled',
          '[&:enabled]:hover:text-primary',
          '[&:enabled]:hover:bg-button-primary-reverse-hover',
        ],
        selected: [
          'text-primary',
          'disabled:text-on-disabled-strong',
          'after:w-[20px]',
          'after:opacity-100',
        ],
      },
    },
  },
);
