import { useId } from 'react';
import type { FontWeightsEnum } from '@mentimeter/ragnar-dsc';
import type { MetaT } from '@mentimeter/plans-content';
import type { ReactNode } from 'react';
import type { TextT } from '@mentimeter/ragnar-ui/text';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { IllustrationTooltip } from './IllustrationTooltip';

interface CustomTextT {
  targetWeight?: FontWeightsEnum;
  targetUnderline?: boolean;
  lighter?: boolean;
}

type MetaTextT = TextT &
  CustomTextT & {
    meta: MetaT;
    iconTrailing?: ReactNode;
  };

export const MetaText = ({ meta, iconTrailing, ...props }: MetaTextT) => {
  const { text, element, tooltips, bold } = meta;
  const { targetUnderline = true } = props;

  const isNormalText = !tooltips && !bold;

  if (element) {
    return (
      <Text fontSize="100" {...props}>
        {element}
        {iconTrailing}
      </Text>
    );
  } else if (isNormalText) {
    return (
      <Text fontSize="100" {...props}>
        {text}
        {iconTrailing}
      </Text>
    );
  }

  const tooltipTargets = tooltips?.map((tooltip) => tooltip.target) ?? [];
  const targetStrings = [...tooltipTargets, ...(bold ? [bold] : [])];
  const specialMatchRegex = new RegExp(`(${targetStrings.join('|')})`);

  const metaText = text.split(specialMatchRegex).map((segment, index) => {
    if (!segment) return null;

    const matchingTooltip = tooltips?.find(
      (tooltip) => tooltip.target === segment,
    );
    const segmentKey = `${segment}-${index}`;

    if (matchingTooltip) {
      return (
        <TooltipSegment
          targetUnderline={targetUnderline}
          key={`tooltip-segment-${segmentKey}`}
          segment={segment}
          text={matchingTooltip.text ?? ''}
          illustrationUrl={matchingTooltip.illustrationUrl ?? undefined}
          {...props}
        />
      );
    } else if (segment === bold) {
      return (
        <Text
          key={segmentKey}
          fontSize="100"
          fontWeight="bold"
          extend={() => ({ display: 'block' })}
          {...props}
        >
          {segment}
        </Text>
      );
    } else {
      return (
        <Text
          key={segmentKey}
          fontSize="100"
          fontWeight={!props.lighter ? 'semiBold' : 'regular'}
          extend={() => ({ display: 'block' })}
          {...props}
        >
          {segment}
        </Text>
      );
    }
  });

  return (
    <Box display="flex-inline" flexDirection="row" width="100%">
      {metaText}
      {iconTrailing}
    </Box>
  );
};

type TooltipSegmentPropsT = TextT &
  CustomTextT & {
    segment: string;
    text: string;
    illustrationUrl: string | undefined;
  };

function TooltipSegment({
  segment,
  text,
  illustrationUrl = undefined,
  targetUnderline = true,
  targetWeight = 'semiBold',
  lighter = false,
  ...props
}: TooltipSegmentPropsT) {
  const id = useId();

  return (
    <Box>
      <Text
        id={id}
        fontSize="100"
        {...props}
        style={{
          ...(targetUnderline ? { textDecoration: 'underline' } : {}),
          textDecorationStyle: 'dotted',
          cursor: 'help',
        }}
        fontWeight={!lighter ? targetWeight : 'regular'}
      >
        {segment}
      </Text>
      <IllustrationTooltip
        referenceId={id}
        illustrationUrl={illustrationUrl}
        text={text}
      />
    </Box>
  );
}
