import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box, type BoxT } from '../box';
import { PillSelectItem, type PillItemT } from './PillSelectItem';
import { PillSelectHighlight } from './PillSelectHighlight';

export interface PillSelectT
  extends Omit<BoxT, 'value' | 'onSelect' | 'width'> {
  defaultValue?: string;
  disabled?: boolean;
  items: PillItemT[];
  value?: string;
  width?: Exclude<React.CSSProperties['width'], number>;
  onSelect?: ((value?: PillItemT) => void) | undefined;
}

export const PillSelect = ({
  items,
  onSelect,
  value,
  defaultValue = value,
  disabled,
  width,
  className,
  style = {},
  ...rest
}: PillSelectT) => {
  const [selectedItem, setSelectedItem] = React.useState<PillItemT>(
    () =>
      (!value && items.find((item) => item.value === defaultValue)) ||
      items[0]!,
  );

  const refs = React.useMemo(
    () => items.map(() => ({ ref: React.createRef<HTMLButtonElement>() })),
    [items],
  );

  const select = React.useCallback(
    (item: PillItemT) => {
      if (selectedItem.value !== item.value) {
        setSelectedItem(item);
        onSelect?.(item);
      }
    },
    [onSelect, selectedItem.value],
  );

  const [allowAnimation, setAllowAnimation] = React.useState(false);

  const [highlightDimensions, setHighlightDimensions] = React.useState({
    width: 0,
    offsetLeft: 0,
  });

  const didMountRef = React.useRef(false);
  React.useEffect(() => {
    if (didMountRef.current) setAllowAnimation(true);
    else didMountRef.current = true;
  }, [value]);

  React.useEffect(() => {
    const item =
      refs[items.findIndex((item) => item.value === selectedItem.value)];

    if (item?.ref?.current)
      setHighlightDimensions({
        width: item.ref.current.offsetWidth,
        offsetLeft: item.ref.current.offsetLeft,
      });
  }, [items, refs, selectedItem.value]);

  React.useEffect(() => {
    if (
      Boolean(value) &&
      value !== null &&
      value !== undefined &&
      selectedItem.value !== value &&
      items?.length > 0
    ) {
      const item = items.find((item) => item.value === value);
      if (item) {
        setSelectedItem(item);
        onSelect?.(item);
      }
    }
  }, [value, items, onSelect, selectedItem.value]);

  React.useEffect(() => {
    const item = items.find((item) => item.disabled);
    if (item?.value === selectedItem?.value) {
      setSelectedItem(item);
    }
  }, [items, selectedItem.value]);

  const defaultClasses = [
    'flex',
    'flex-row',
    'items-stretch',
    'bg-surface-sunken',
    'rounded-full',
    'p-1',
    'w-[var(--pill-select-width,auto)]',
  ];

  return (
    <Box
      role="tablist"
      onMouseEnter={() => setAllowAnimation(true)}
      onFocus={() => setAllowAnimation(true)}
      className={clsx(defaultClasses, className)}
      {...rest}
      style={
        {
          '--pill-select-width': `${width}`,
          ...style,
        } as React.CSSProperties
      }
    >
      <Box
        className={clsx([
          'w-full',
          'relative',
          'flex',
          'flex-row',
          'items-stretch',
          'gap-1.5',
          'justify-stretch',
        ])}
      >
        <PillSelectHighlight
          disabled={disabled}
          allowAnimation={allowAnimation}
          highlightWidth={highlightDimensions.width}
          highlightOffset={highlightDimensions.offsetLeft}
        />

        {items.map((item, i) => (
          <PillSelectItem
            key={item.value}
            id={item.id}
            ref={refs[i]!.ref}
            label={item.label}
            value={item.value}
            aria-label={item['aria-label'] || item.label}
            disabled={disabled || item.disabled}
            selected={selectedItem.value === item.value}
            onClick={() => select(item)}
          />
        ))}
      </Box>
    </Box>
  );
};
