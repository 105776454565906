import { type ButtonT, Button } from '@mentimeter/ragnar-ui/button';

export const BuyPlanButton = ({
  children,
  highlight = false,
  ...rest
}: ButtonT & {
  highlight?: boolean | undefined;
}) => {
  return (
    <Button width="100%" variant={highlight ? 'primary' : 'outline'} {...rest}>
      {children}
    </Button>
  );
};
