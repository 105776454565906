import { alpha } from '@mentimeter/ragnar-colors';
import { useRagnar } from '@mentimeter/ragnar-react';
import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';

interface Props {
  width?: string;
  height?: string | string[];
}

export const AnimatedTextSkeleton = ({
  width = '100px',
  height = '40px',
}: Props) => {
  const { renderer } = useRagnar();

  const keyframes =
    renderer.renderKeyframe &&
    renderer.renderKeyframe(
      () => ({
        '0%': {
          transform: 'translateX(-200%)',
        },
        '100%': {
          transform: 'translateX(200%)',
        },
      }),
      {},
    );

  return (
    <Box
      width={width}
      height={height}
      bg="neutralWeakest"
      borderRadius={1}
      overflow="hidden"
    >
      <Box
        width="90%"
        height={height}
        borderColor="border"
        borderWidth={1}
        style={{
          animationDuration: '1200ms',
          animationFillMode: 'none',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'linear',
          background: `linear-gradient(90deg, ${alpha('white', 0.1)}, ${alpha(
            'white',
            0.8,
          )}, ${alpha('white', 0.1)}`,
          position: 'relative',
          animationName: keyframes,
          content: 'none',
        }}
      ></Box>
    </Box>
  );
};
