import * as React from 'react';
import { CheckIcon, QuestionMarkCircleIcon } from '@mentimeter/ragnar-visuals';
import type { UspT } from '@mentimeter/plans-content';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { MetaText } from './MetaText';
import { IllustrationTooltip } from './IllustrationTooltip';

interface UspsProps {
  disabled?: boolean;
  heading?: string | undefined;
  highlight?: boolean;
  usps: Array<UspT | false>;
}

export const Usps = ({ disabled, highlight, heading, usps }: UspsProps) => {
  return (
    <Box
      width="100%"
      extend={({ theme }) => ({
        opacity: disabled ? theme.opacity['disabled'] : 1,
      })}
    >
      {heading && (
        <Text
          color="text"
          fontSize="100"
          fontWeight="semiBold"
          lineHeight="normal"
          mb="space2"
        >
          {heading}
        </Text>
      )}
      <Box
        alignItems="baseline"
        display="grid"
        gap="space2"
        gridTemplateColumns="20px 1fr"
      >
        {usps.filter(isDefined).map((usp) => (
          <React.Fragment key={usp.text}>
            <Box top="3px">
              <CheckIcon color={highlight ? 'primary' : 'textWeak'} />
            </Box>
            <Box>
              <MetaText meta={usp} lighter />
              {usp.subheading && (
                <MetaText
                  targetUnderline={false}
                  meta={usp.subheading}
                  fontSize="75"
                  color="textWeak"
                  mt="space0.5"
                  iconTrailing={
                    usp.subheading.iconTooltip && (
                      <>
                        <Box
                          ml="2px"
                          mt="space1"
                          id={`${usp.text}-question-mark`}
                        >
                          <QuestionMarkCircleIcon size={0} color="textWeaker" />
                        </Box>
                        <IllustrationTooltip
                          referenceId={`${usp.text}-question-mark`}
                          illustrationUrl={
                            usp.subheading.iconTooltip.illustrationUrl
                          }
                          text={usp.subheading.iconTooltip.text}
                        />
                      </>
                    )
                  }
                />
              )}
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

function isDefined<T>(argument: T | undefined | false): argument is T {
  return Boolean(argument);
}
