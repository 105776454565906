import type { TooltipGenericT } from '@mentimeter/ragnar-ui/tooltip-legacy';
import { TooltipGeneric } from '@mentimeter/ragnar-ui/tooltip-legacy';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Image } from '@mentimeter/ragnar-ui/image';
import { Text } from '@mentimeter/ragnar-ui/text';

export function IllustrationTooltip({
  text,
  illustrationUrl,
  referenceId,
}: {
  illustrationUrl?: string | undefined;
  text: string;
  referenceId: TooltipGenericT['referenceId'];
}) {
  return (
    <TooltipGeneric referenceId={referenceId}>
      <Box py="3" px="3" width="242px" borderRadius={1} bg="highContrast">
        {illustrationUrl && (
          <Box mb={2} mt={1}>
            <Image src={illustrationUrl} />
          </Box>
        )}
        <Text fontSize={1} color="onHighContrast">
          {text}
        </Text>
      </Box>
    </TooltipGeneric>
  );
}
